import * as React from "react";

import {
  Grid,
  List
} from "@material-ui/core";

import { Wrapper, ListItem, ListItemText } from "./styles";

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Grid container item xs={12} md={6} justify="flex-end">
          <List>
            <ListItem button={true}>
              <ListItemText
                primary={`© ${new Date().getFullYear()} - Bauer Media Ltd`}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;
