import React from "react";
import styled from "styled-components/macro";

import {
    Divider as MuiDivider,
    Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import MyBreadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { httpService } from "../../services/httpService";
import VendorForm from "./VendorForm";

const Divider = styled(MuiDivider)(spacing);

function VendorAdd(props: any) {
    const breadcrumbs = {
        links: [
            {
                name: "Vendors",
                to: `/stores/${props.match.params.storeId}/vendors`,
            },
        ],
        current: "Add Vendor",
    };
    const config = useSelector((state: any) => state.config);
    const handleSubmit = async (values: any, functions: any) => {
        httpService.create(
            values,
            functions,
            config,
            `/stores/${props.match.params.storeId}/vendors`
        );
    };

    return (
        <React.Fragment>
            <Helmet title="Vendors - Add Vendor" />
            <Typography variant="h3" gutterBottom display="inline">
                Add Vendor
            </Typography>
            <MyBreadcrumbs {...breadcrumbs} />
            <Divider my={6} />
            <VendorForm {...props} handleSubmit={handleSubmit} initialValues={{}} />
        </React.Fragment>
    );
}

export default VendorAdd;
