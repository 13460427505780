import React from "react";
import styled from "styled-components/macro";

import { Grid, TextField as MuiTextField } from "@material-ui/core";

import { spacing } from "@material-ui/system";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export const DateFields = ({
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
    dateFromRequired = true,
    dateToRequired = true,
}: {
    errors: any;
    handleBlur: any;
    handleChange: any;
    touched: any;
    values: any;
    dateFromRequired?: boolean;
    dateToRequired?: boolean;
}) => {
    return (
        <Grid container spacing={6}>
            <Grid item md={6}>
                <TextField
                    id="dateFrom"
                    label="Date From"
                    type="date"
                    value={values.dateFrom || ""}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={Boolean(touched.dateFrom && errors.dateFrom)}
                    fullWidth
                    helperText={touched.dateFrom && errors.dateFrom}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                    required={dateFromRequired}
                />
            </Grid>
            <Grid item md={6}>
                <TextField
                    id="dateTo"
                    label="Date To"
                    type="date"
                    value={values.dateTo || ""}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={Boolean(touched.dateTo && errors.dateTo)}
                    fullWidth
                    helperText={touched.dateTo && errors.dateTo}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                    required={dateToRequired}
                />
            </Grid>
        </Grid>
    );
};
