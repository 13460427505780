import * as Yup from "yup";

export const alphanumericTextValidation = (
    name: string,
    required: boolean = true
): any => {
    const validation = Yup.string().matches(
        /^[a-zA-Z0-9\-_,.()&£!'\n ]+$/,
        "Must be alphanumeric. Additionally, only the following characters are allowed: -_,.()&£! and Newline (Enter)"
    );
    return required
        ? { [name]: validation.required("Required") }
        : { [name]: validation };
};
