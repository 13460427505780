import * as Yup from "yup";

export const emailValidation = {
    emails: Yup.array()
        .of(
            Yup.string()
                .email((email) => `${email.value} isn't a valid email. `)
                .required("Required")
                .notOneOf(
                    ["example@email.com"],
                    "example@email.com isn't a valid email "
                )
        )
        .required("Required")
        .min(1, "Provide at least 1 email"),
};
