import * as Yup from "yup";
import formatDate from "../helpers/date";

export const openDateValidation = {
    dateFrom: Yup.date().required("Required"),
    dateTo: Yup.date()
        .min(
            Yup.ref("dateFrom"),
            ({ min }) => `Date To needs to be after ${formatDate(min)}`
        ),
};
