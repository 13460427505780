import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	width: 11rem;
	height: auto;
	background: #233044;
`;

export const Card = styled.div<{ width: number; height: number }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	width: ${(props) => props.width}rem;
	height: ${(props) => props.height}rem;
	padding: 15px;
	margin-top: 0;
	border-radius: 0.3rem;
	display: flex;
	justify-content: center;
	border: 1px solid #4f5966;
	background: #1b2635;
	background-repeat: no-repeat, no-repeat;
	background-position: 50% 5%, 200% -40%;
	background-size: 55%, 80%;

	&:hover {
		border: 1px solid #ccc;
	}

	Button {
		position: absolute;
		bottom: 20px;
		border-radius: 0.3rem;
		border: 1px solid #ed3a53;
		background: none;
		width: 50%;
		color: #ed3a53;
		height: 30px;
		font-weight: bold;
		cursor: pointer;

		&:hover {
			border: 1px solid #fd637b;
			background: #fd637b;
			color: #fff;
		}
	}

	section {
		display: flex;
		justify-content: center;
		flex-direction: column;
		position: absolute;
		margin-top: 1.3rem;
		width: 70%;
		text-align: center;
		padding-top: 10px;
		background: rgba(35, 48, 68, 0.5);
		border-radius: 0.3rem;

		span {
			font-size: 10px;
			margin-bottom: 8px;
			height: 18px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
`;

export const Item = styled.div<{
	top: number;
	width: number;
	height: number;
}>`
	width: ${(props) => props.width}px;
	height: ${(props) => props.height}px;
	margin-top: ${(props) => props.top}px;
	background: #233044;
	border-radius: 0.3rem;
	display: flex;
	justify-content: center;
	align-items: center;

	svg {
		width: 60px;
		height: 60px;
	}

	img {
		width: ${(props) => props.width}px;
		height: ${(props) => props.height}px;
		border-radius: 0.3rem;
	}

	input[type="file"] {
		opacity: 0;
		position: absolute;
		border-radius: 0.3rem;
		cursor: pointer;
		z-index: 99999;
		width: 100%;
		height: 145px;
	}

	&:hover {
		border: 1px solid #4f5966;
	}
`;
