import React from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import { Typography } from "@material-ui/core";

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function Page403() {
  return (
    <Wrapper>
      <Helmet title="404 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        403 Permission Denied
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        It seems you don't have permission to use this resource.
      </Typography>
      <Typography component="h2" variant="body2" align="center" gutterBottom>
        Please contact the administrator.
      </Typography>
    </Wrapper>
  );
}

export default Page403;
