import React from "react";
import { Typography } from "@material-ui/core";
import { Box } from "../../../components/Sidebar/styles";

export const CardHeader = () => {
    return (
        <Box mb={4}>
            <Typography variant="body2" gutterBottom>
                Please fill in these details to send the report via email.
            </Typography>
        </Box>
    );
};
