/* eslint-disable import/first */
import React from "react";
import { List as ListIcon, FileText as FileTextIcon } from "react-feather";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Pages components
import VendorsList from "../pages/vendors/VendorsList";
import VendorAdd from "../pages/vendors/VendorAdd";
import VendorEdit from "../pages/vendors/VendorEdit";
import Default from "../pages/default";
import Reports from "../pages/reports/Reports";
import Page403 from "../pages/auth/Page403";
import CodepoolAdd from "../pages/codepools/CodepoolAdd";
import CodepoolEdit from "../pages/codepools/CodepoolEdit";
import Stores from "../pages/stores/Stores";
import OfferEdit from "../pages/offers/OfferEdit";
import VoucherCodeAdd from '../pages/voucher-codes/VoucherCodeAdd';

const defaultRoutes = {
	id: "Default",
	path: "/stores/:storeId",
	component: Default,
	children: null,
};

/**
 * @note: "name" field in children must always start with upper case.
 * It'll translate into a component later
 */
const reportRoutes = {
	id: "Reports",
	path: "/reports",
	icon: <FileTextIcon />,
	containsHome: true,
	children: [
		{
			path: "/reports/sales",
			name: "Sales",
			component: Reports,
		},
		{
			path: "/reports/refunds",
			name: "Refunds",
			component: Reports,
		},
		{
			path: "/reports/products",
			name: "Products",
			component: Reports,
		},
		{
			path: "/reports/stock",
			name: "Stock",
			component: Reports,
		},
		{
			path: "/reports/customers",
			name: "Customers",
			component: Reports,
		},
	],
	component: null,
};

const sidebarVendorRoutes = (storeId: string) => {
	return {
		id: "Vendors",
		path: `/stores/${storeId}/vendors`,
		icon: <ListIcon />,
		component: VendorsList,
		children: null,
	};
};

const vendorRoutes = {
	id: "Vendors",
	path: "/stores/:storeId/vendors",
	icon: <ListIcon />,
	component: null,
	children: [
		{
			path: "/stores/:storeId/vendors",
			name: "List",
			component: VendorsList,
		},
		{
			path: "/stores/:storeId/vendors/create",
			name: "Add",
			component: VendorAdd,
		},
		{
			path: "/stores/:storeId/vendors/:vendorId/edit",
			name: "Edit",
			component: VendorEdit,
		},
	],
};

const codepoolRoutes = {
	id: "Codepools",
	path: "/stores/:storeId/vendors/:vendorId",
	component: null,
	children: [
		{
			path: "/stores/:storeId/vendors/:vendorId/codepools/create",
			name: "Add",
			component: CodepoolAdd,
		},
		{
			path:
				"/stores/:storeId/vendors/:vendorId/codepools/:codepoolId",
			name: "Edit",
			component: CodepoolEdit,
		},
	],
};

const voucherCodeRoutes = {
	id: "VoucherCodes",
	path: "/stores/:storeId/vendors/:vendorId/codepools",
	component: null,
	children: [
		{
			path: "/stores/:storeId/vendors/:vendorId/codepools/:codepoolId/codes/generate",
			name: "Add",
			component: VoucherCodeAdd,
		},
	],
};

const offerRoutes = {
	id: "Offers",
	path: "/stores/:storeId/vendors/:vendorId",
	component: null,
	children: [
		{
			path: "/stores/:storeId/vendors/:vendorId/offers/:productId",
			name: "Edit",
			component: OfferEdit,
		},
	],
};

const errorRoutes = {
	id: "Auth",
	path: "/error",
	icon: <ListIcon />,
	children: [
		{
			path: "/error/403",
			name: "403 Page",
			component: Page403,
		},
	],
	component: null,
};

const storeRoutes = {
	id: "Default",
	path: "/",
	component: Stores,
	children: null,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
	defaultRoutes,
	vendorRoutes,
	reportRoutes,
	codepoolRoutes,
	offerRoutes,
    voucherCodeRoutes,
];

// Routes visible in the sidebar
export function sidebarRoutes(params: any) {
	return [sidebarVendorRoutes(params.storeId), reportRoutes];
}

// Routes using the Error layout
export const errorLayoutRoutes = [errorRoutes];

export const landingLayoutRoutes = [storeRoutes];
