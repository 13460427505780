import * as Yup from "yup";
import formatDate from "../helpers/date";

export const datesValidation = {
    dateFrom: Yup.date().required("Required"),
    dateTo: Yup.date()
        .required("Required")
        .min(
            Yup.ref("dateFrom"),
            ({ min }) => `Date To needs to be after ${formatDate(min)}`
        ),
};
