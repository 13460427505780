import styled, { createGlobalStyle } from "styled-components/macro";
import { GlobalStyleProps } from "../../types/styles";
import { RouteType } from "../../types/routes";
import { spacing } from "@material-ui/system";
import { Paper as MuiPaper } from "@material-ui/core";

export const drawerWidth = 258;

export const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

export const Root = styled.div`
    display: flex;
    min-height: 100vh;
`;

export const Drawer = styled.div`
    ${(props) => props.theme.breakpoints.up("md")} {
        width: ${drawerWidth}px;
        flex-shrink: 0;
    }
`;

export const AppContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

export const MainContent = styled(Paper)`
    flex: 1;
    background: ${(props) => props.theme.palette.background.default};

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        flex: none;
    }
`;

export type DashboardPropsType = {
    routes: Array<RouteType>;
    width: "md" | "xs" | "sm" | "lg" | "xl";
};
