import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";

import {
    Breadcrumbs as MuiBreadcrumbs,
    Link,
    Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { breadcrumbLinks } from './module';

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const MyBreadcrumbs = ({links, current}: {links: breadcrumbLinks, current: string}) => {
    const linksList: any = [];
    for (const link of links) {
        linksList.push(
            <Link component={NavLink} exact to={link.to} key={link.name}>
                {link.name}
            </Link>
        );
    }
    return (
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} exact to="/">
                Dashboard
            </Link>
            {linksList}
            <Typography>{current}</Typography>
        </Breadcrumbs>
    );
};
export default MyBreadcrumbs;