import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	RouteComponentProps,
} from "react-router-dom";
import { errorLayoutRoutes, dashboardLayoutRoutes, landingLayoutRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard/Dashboard";
import ReducedDashboard from "../layouts/Dashboard/ReducedDashboard";
import { RouteType } from "../types/routes";
import Page404 from "../pages/auth/Page404";
import AuthLayout from "../layouts/Auth/Auth";

const childRoutes = (Layout: React.ElementType, routes: Array<RouteType>) =>
	routes.map(
		({ component: Component, guard, children, path }, index: number) => {
			const Guard = guard || React.Fragment;
			return children ? (
				children.map((element, index: number) => {
					const Guard = element.guard || React.Fragment;
					const ElementComponent =
						element.component || React.Fragment;

					return (
						<Route
							key={index}
							path={element.path}
							exact
							render={(props: RouteComponentProps) => (
								<Layout>
									<Guard>
										<ElementComponent
											{...props}
											name={element.name}
										/>
									</Guard>
								</Layout>
							)}
						/>
					);
				})
			) : Component ? (
				<Route
					key={index}
					path={path}
					exact
					render={(props) => (
						<Layout>
							<Guard>
								<Component {...props} />
							</Guard>
						</Layout>
					)}
				/>
			) : null;
		}
	);

const Routes = () => (
	<Router basename={'/'}>
		<Switch>
			{childRoutes(DashboardLayout, dashboardLayoutRoutes)}
			{childRoutes(ReducedDashboard, errorLayoutRoutes)}
			{childRoutes(ReducedDashboard, landingLayoutRoutes)}
			<Route
				render={() => (
					<AuthLayout>
						<Page404 />
					</AuthLayout>
				)}
			/>
		</Switch>
	</Router>
);

export default Routes;
