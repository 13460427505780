import React from "react";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import { Alert as MuiAlert } from "@material-ui/lab";

const Alert = styled(MuiAlert)(spacing);

export const StatusMessage = ({
    status,
    errors,
    successMessage,
}: {
    status: any;
    errors: any;
    successMessage: string;
}) => {
    return (
        <>
            {(status && status.sent && (
                <Alert severity="success" my={3}>
                    {successMessage}
                </Alert>
            )) ||
                (errors && errors.submit && (
                    <Alert severity="error" my={3}>
                        {errors.submit}
                    </Alert>
                ))}
        </>
    );
};
