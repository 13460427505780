import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import {
    Divider as MuiDivider,
    Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import MyBreadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { httpService } from "../../services/httpService";
import VendorForm from "./VendorForm";
import { VendorFormDto } from "./module";
import { transform } from "./transformer";

const Divider = styled(MuiDivider)(spacing);

function VendorEdit(props: any) {
    const [vendor, setVendor] = useState({} as VendorFormDto);
    const breadcrumbs = {
        links: [
            {
                name: "Vendors",
                to: `/stores/${props.match.params.storeId}/vendors`,
            },
        ],
        current: "Edit Vendor",
    };
    const config = useSelector((state: any) => state.config);
    
    const handleSubmit = async (values: any, functions: any) => {
        httpService.update(
            transform(values),
            functions,
            config,
            `/stores/${props.match.params.storeId}/vendors/${props.match.params.vendorId}`,
        );
    };

    useEffect(() => {
        httpService
            .getById(
                config,
                `/stores/${props.match.params.storeId}/vendors/${props.match.params.vendorId}`
            )
            .then((res: any) => setVendor(transform(res)));
    }, [config, props]);

    return (
        <React.Fragment>
            <Helmet title="Vendors - Edit Vendor" />
            <Typography variant="h3" gutterBottom display="inline">
                Edit Vendor
            </Typography>
            <MyBreadcrumbs {...breadcrumbs} />
            <Divider my={6} />
            <VendorForm {...props} handleSubmit={handleSubmit} initialValues={vendor} />
        </React.Fragment>
    );
}

export default VendorEdit;
