import * as Yup from "yup";

export const skuValidation = {
    sku: Yup.string()
        .matches(
            /^[a-zA-Z0-9\-_ ]+$/,
            "Must be alphanumeric with hyphen/underscore/spaces"
        )
        .required("Required")
        .notOneOf(
            ["YOUR-SKU-EXAMPLE-000000000"],
            "YOUR-SKU-EXAMPLE-000000000 isn't a valid SKU "
        )
};