import React from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Form, Formik } from "formik";

import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    CircularProgress,
    Grid,
    Typography,
    FormControl,
    FormGroup,
    FormControlLabel,
    Switch,
} from "@material-ui/core";
import { spacing, SpacingProps } from "@material-ui/system";

import { StatusMessage } from "../../components/Form/Sections/StatusMessage";
import { nameValidation } from "../../components/Form/validation/nameValidation";
import { SimpleTextField } from "../../components/Form/Fields/SimpleTextField";
import { makeStyles } from "@material-ui/styles";
import { singleEmailValidation } from "../../components/Form/validation/singleEmailValidation";
import { alphanumericExtValidation } from "../../components/Form/validation/alphanumericExtValidation";
import { alphanumericValidation } from "../../components/Form/validation/alphanumericValidation";
import { alphabeticValidation } from "../../components/Form/validation/alphabeticValidation";
import { websiteValidation } from "../../components/Form/validation/websiteValidation";

const Card = styled(MuiCard)(spacing);

interface ButtonPropstype extends SpacingProps {
    component?: string;
}

const Button = styled(MuiButton)<ButtonPropstype>(spacing);
const cityValidation = alphabeticValidation("city");
const countyValidation = alphabeticValidation("county");
const contactValidation = alphabeticValidation("contact");
const address1Validation = alphanumericExtValidation("address1");
const address2Validation = alphanumericExtValidation("address2", false);
const postcodeValidation = alphanumericValidation("postcode");

const validationSchema = Yup.object().shape({
    ...nameValidation,
    ...singleEmailValidation,
    ...contactValidation,
    ...address1Validation,
    ...address2Validation,
    ...cityValidation,
    ...countyValidation,
    ...postcodeValidation,
    ...websiteValidation,
    ...{
        phone: Yup.number().nullable(),
    },
});
const useStyles = makeStyles(() => ({
    detailsSection: {
        marginTop: "1.3rem",
        marginBottom: "0.5rem",
    },
}));

function VendorForm(props: any) {
    const classes = useStyles();

    return (
        <Formik
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={props.handleSubmit}
            enableReinitialize
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
            }) => (
                <Card mb={6}>
                    <CardContent>
                        <StatusMessage
                            status={status}
                            errors={errors}
                            successMessage={"Successfully saved"}
                        />

                        {isSubmitting ? (
                            <Box display="flex" justifyContent="center" my={6}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Form onSubmit={handleSubmit}>
                                <Grid container spacing={6}>
                                    <Grid item md={6}>
                                        <SimpleTextField
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            name="name"
                                            label="Name"
                                            placeholder="My Vendor name"
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <SimpleTextField
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            name="email"
                                            label="Email"
                                            placeholder="email@vendor.com"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs
                                    zeroMinWidth
                                    className={classes.detailsSection}
                                >
                                    <Typography variant="body2" gutterBottom>
                                        Address details
                                    </Typography>
                                </Grid>
                                <Grid container spacing={6}>
                                    <Grid item md={6}>
                                        <SimpleTextField
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            name="address1"
                                            label="Address (Line 1)"
                                            placeholder="Line 1 of address"
                                        />

                                        <SimpleTextField
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            name="address2"
                                            label="Address (Line 2)"
                                            placeholder="Line 2 of address"
                                            required={false}
                                        />
                                    </Grid>
                                    <Grid item md={6}>
                                        <SimpleTextField
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            name="city"
                                            label="City"
                                            placeholder="City"
                                        />
                                        <SimpleTextField
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            name="county"
                                            label="County"
                                            placeholder="County"
                                        />
                                        <SimpleTextField
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            name="postcode"
                                            label="Postcode"
                                            placeholder="Postcode"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs
                                    zeroMinWidth
                                    className={classes.detailsSection}
                                >
                                    <Typography variant="body2" gutterBottom>
                                        Contact details
                                    </Typography>
                                </Grid>
                                <Grid container spacing={6}>
                                    <Grid item md={4}>
                                        <SimpleTextField
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            name="contact"
                                            label="Contact"
                                            placeholder="John Doe"
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <SimpleTextField
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            name="phone"
                                            label="Phone"
                                            placeholder="Phone"
                                        />
                                    </Grid>
                                    <Grid item md={4}>
                                        <SimpleTextField
                                            errors={errors}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            touched={touched}
                                            values={values}
                                            name="website"
                                            label="Website"
                                            placeholder="Website"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    xs
                                    zeroMinWidth
                                    className={classes.detailsSection}
                                >
                                    <Typography variant="body2" gutterBottom>
                                        Voucher redeption details
                                    </Typography>
                                </Grid>
                                <Grid container spacing={6}>
                                    <Grid item md={4}>
                                        <FormControl component="fieldset">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={
                                                                values?.redemptionEnabled || false
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            name="redemptionEnabled"
                                                        />
                                                    }
                                                    label="Enable Voucher Redemption?"
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    mt={3}
                                >
                                    Save
                                </Button>
                            </Form>
                        )}
                    </CardContent>
                </Card>
            )}
        </Formik>
    );
}

export default VendorForm;
