import HttpClient from "../utils/httpClient";
import {
	setFormFailure,
	setFormSuccess,
} from "../components/Form/helpers/responseSetter";

export const httpService = {
	getAll,
	getById,
	create,
	update,
	_delete,
};

async function getAll(config: any, endpoint: string) {
	return new Promise((resolve, reject) => {
		new HttpClient(config)
			.get(endpoint)
			.then((res: any) => resolve(res.data))
			.catch((e: Error) => reject(e));
	});
}

async function getById(config: any, endpoint: string) {
	return new Promise((resolve, reject) => {
		new HttpClient(config)
			.get(endpoint)
			.then((res: any) => {
				resolve(res.datum);
			})
			.catch((e: Error) => reject(e));
	});
}

async function create(
	values: any,
	{ resetForm, setErrors, setStatus, setSubmitting, toFormValues }: any,
	config: any,
	endpoint: any
) {
	new HttpClient(config)
		.post(endpoint, values)
		.then(() =>
			setFormSuccess(
				{ resetForm, setStatus, setSubmitting, toFormValues },
				values
			)
		)
		.catch((error) => {
			setFormFailure(
				{ setErrors, setStatus, setSubmitting },
				error.message
			);
		});
}

async function update(
	values: any,
	{ resetForm, setErrors, setStatus, setSubmitting, toFormValues }: any,
	config: any,
	endpoint: any
) {
	new HttpClient(config)
		.patch(endpoint, values)
		.then(() => {
			setFormSuccess(
				{ resetForm, setStatus, setSubmitting, toFormValues },
				values
			);
		})
		.catch((error) => {
			setFormFailure(
				{ setErrors, setStatus, setSubmitting },
				error.message
			);
		});
}

/**
 * @todo to be implemented in a separate ticket
 * @note delete is a reserved word, prefixing with _
 */
function _delete() {}
