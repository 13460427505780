import React, { useState } from "react";

import { NavLink, withRouter, RouteComponentProps } from "react-router-dom";
import { RouteType, RouteChildType } from "../../types/routes";
import { Collapse } from "@material-ui/core";
import { sidebarRoutes } from "../../routes/index";

import {
	Box,
	Drawer,
	Scrollbar,
	List,
	Items,
	Brand,
	BrandIcon,
	BrandChip,
	Category,
	CategoryText,
	CategoryIconLess,
	CategoryIconMore,
	Link,
	LinkText,
	LinkBadge,
	CategoryBadge,
	SidebarSection,
} from "./styles";

type SidebarCategoryPropsType = {
	name: string;
	icon: JSX.Element;
	classes?: string;
	isOpen?: boolean;
	isCollapsable: boolean;
	badge?: string | number;
	activeClassName?: string;
	button: true;
	onClick?: () => void;
	to?: string;
	component?: typeof NavLink;
	exact?: boolean;
};

const SidebarCategory: React.FC<SidebarCategoryPropsType> = ({
	name,
	icon,
	classes,
	isOpen,
	isCollapsable,
	badge,
	...rest
}) => {
	return (
		<Category {...rest}>
			{icon}
			<CategoryText>{name}</CategoryText>
			{isCollapsable ? (
				isOpen ? (
					<CategoryIconMore />
				) : (
					<CategoryIconLess />
				)
			) : null}
			{badge ? <CategoryBadge label={badge} /> : ""}
		</Category>
	);
};

type SidebarLinkPropsType = {
	name: string;
	to: string;
	badge?: string | number;
	icon?: JSX.Element;
};

const SidebarLink: React.FC<SidebarLinkPropsType> = ({
	name,
	to,
	badge,
	icon,
}) => {
	return (
		<Link
			button
			dense
			component={NavLink}
			exact
			to={to}
			activeClassName="active"
		>
			<LinkText>{name}</LinkText>
			{badge ? <LinkBadge label={badge} /> : ""}
		</Link>
	);
};

type SidebarPropsType = {
	classes?: string;
	staticContext: string;
	location: {
		pathname: string;
	};
	routes: Array<RouteType>;
	PaperProps: {
		style: {
			width: number;
		};
	};
	variant?: "permanent" | "persistent" | "temporary";
	open?: boolean;
	onClose?: () => void;
	disableMenu?: boolean;
};

const Sidebar: React.FC<RouteComponentProps & SidebarPropsType> = ({
	classes,
	staticContext,
	location,
	disableMenu = false,
	...rest
}) => {
	type tplotOptions = {
		[key: number]: boolean;
	};
    const routes = sidebarRoutes(rest.match.params);

	const initOpenRoutes = (): tplotOptions => {
		/* Open collapse element that matches current url */
		const pathName = location.pathname;

		let _routes = {};

		routes.forEach((route: RouteType, index) => {
			const isActive = pathName.indexOf(route.path) === 0;
			const isOpen = route.open;
			const isHome = route.containsHome && pathName === "/";

			_routes = { ..._routes, [index]: isActive || isOpen || isHome };
		});

		return _routes;
	};

	const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

	const toggle = (index: number) => {
		// Collapse all elements
		Object.keys(openRoutes).forEach(
			(item) =>
				openRoutes[index] ||
				setOpenRoutes((openRoutes) => ({
					...openRoutes,
					[item]: false,
				}))
		);

		// Toggle selected element
		setOpenRoutes((openRoutes) => ({
			...openRoutes,
			[index]: !openRoutes[index],
		}));
	};

	return (
		<Drawer variant="permanent" {...rest}>
			<Brand component={NavLink} to="/" button>
				<BrandIcon />{" "}
				<Box ml={1}>
					Vendors Admin <BrandChip label="V1" />
				</Box>
			</Brand>
			{!disableMenu && (
				<Scrollbar>
					<List disablePadding>
						<Items>
							{routes.map((category: RouteType, index) => (
								<React.Fragment key={index}>
									{category.header ? (
										<SidebarSection>
											{category.header}
										</SidebarSection>
									) : null}

									{category.children && category.icon ? (
										<React.Fragment key={index}>
											<SidebarCategory
												isOpen={!openRoutes[index]}
												isCollapsable={true}
												name={category.id}
												icon={category.icon}
												button={true}
												onClick={() => toggle(index)}
											/>

											<Collapse
												in={openRoutes[index]}
												timeout="auto"
												unmountOnExit
											>
												{category.children.map(
													(
														route: RouteChildType,
														index: number
													) => (
														<SidebarLink
															key={index}
															name={route.name}
															to={route.path}
															icon={route.icon}
															badge={route.badge}
														/>
													)
												)}
											</Collapse>
										</React.Fragment>
									) : category.icon ? (
										<SidebarCategory
											isCollapsable={false}
											name={category.id}
											to={category.path}
											activeClassName="active"
											component={NavLink}
											icon={category.icon}
											exact
											button
											badge={category.badge}
										/>
									) : null}
								</React.Fragment>
							))}
						</Items>
					</List>
				</Scrollbar>
			)}
		</Drawer>
	);
};

export default withRouter(Sidebar);
