export const setFormSuccess = (
    {
        resetForm,
        setStatus,
        setSubmitting,
        toFormValues = (values: any) => values,
    }: any,
    values: any
) => {
    resetForm({ values: toFormValues(values) });
    setStatus({ sent: true });
    setSubmitting(false);
};

export const setFormFailure = (
    { setErrors, setStatus, setSubmitting }: any,
    errorMessage: string
) => {
    setStatus({ sent: false });
    setErrors({ submit: `API: ${errorMessage}` });
    setSubmitting(false);
};
