import React from "react";
import styled from "styled-components/macro";
import { TextField as MuiTextField } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { TextFieldProps } from "./module";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export const SimpleTextField = (props: TextFieldProps) => {
	const fieldProps = { 
		...props,
		...{
			value: props.values[props.name] || '',
			label: props.label || '',
			onBlur: props.handleBlur,
			onChange: props.handleChange,
			error: Boolean(props.touched[props.name] && props.errors[props.name]),
			helperText: props.touched[props.name] && props.errors[props.name],
			my: 1,
		}
	};

	delete fieldProps.handleBlur;
	delete fieldProps.handleChange;

	if (false === props.required) {
		delete fieldProps.handleBlur;
	}

	return (
		<TextField
			fullWidth
			variant={"outlined"}
			{ ...fieldProps }
		/>
	);
};
