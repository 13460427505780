import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import {
	Box,
	Button,
	Checkbox,
	Grid,
	IconButton,
	Paper as MuiPaper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Toolbar,
	Tooltip,
	Typography,
} from "@material-ui/core";
import {
	Add as AddIcon,
	DeleteForever,
	Edit,
	FilterList as FilterListIcon,
	RemoveRedEye as RemoveRedEyeIcon,
} from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import HttpClient from "../../utils/httpClient";
import { RowType, getComparator, stableSort } from "../../utils/tableHelpers";

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
	flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
	min-width: 150px;
`;

type HeadCell = {
	id: string;
	alignment: "left" | "center" | "right" | "justify" | "inherit" | undefined;
	label: string;
	disablePadding?: boolean;
};
const headCells: Array<HeadCell> = [
	{ id: "id", alignment: "left", label: "ID" },
	{ id: "name", alignment: "left", label: "Name" },
	{ id: "contact", alignment: "left", label: "Contact" },
	{ id: "phone", alignment: "right", label: "Telephone" },
	{ id: "email", alignment: "left", label: "Email" },
	{ id: "actions", alignment: "right", label: "Actions" },
];

type EnhancedTableHeadPropsType = {
	numSelected: number;
	order: "desc" | "asc";
	orderBy: string;
	rowCount: number;
	onSelectAllClick: (e: any) => void;
	onRequestSort: (e: any, property: string) => void;
};
const EnhancedTableHead: React.FC<EnhancedTableHeadPropsType> = (props) => {
	const {
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
	} = props;
	const createSortHandler = (property: string) => (event: any) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={
							numSelected > 0 && numSelected < rowCount
						}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ "aria-label": "select all" }}
					/>
				</TableCell>
				{headCells.map((headCell: HeadCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.alignment}
						padding={headCell.disablePadding ? "none" : "default"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

type EnhancedTableToolbarPropsType = { numSelected: number };
const EnhancedTableToolbar = (props: EnhancedTableToolbarPropsType) => {
	const { numSelected } = props;

	return (
		<Toolbar>
			<ToolbarTitle>
				{numSelected > 0 ? (
					<Typography color="inherit" variant="subtitle1">
						{numSelected} selected
					</Typography>
				) : (
					<Typography variant="h6" id="tableTitle">
						All Vendors List
					</Typography>
				)}
			</ToolbarTitle>
			<Spacer />
			<div>
				{numSelected > 0 ? (
					<Tooltip title="Delete">
						<IconButton aria-label="Delete">
							<DeleteForever />
						</IconButton>
					</Tooltip>
				) : (
					<Tooltip title="Filter list">
						<IconButton aria-label="Filter list">
							<FilterListIcon />
						</IconButton>
					</Tooltip>
				)}
			</div>
		</Toolbar>
	);
};

function EnhancedTable(props: any) {
    const [vendors, setVendors] = useState([]);
	const config =  useSelector((state:any) => state.config);

	useEffect(() => {
		const getAllVendors = () => {
			/**
			 * @todo Instead of hardcoded store 1, use storeId from user selection
             * @todo Create VendorService and use it in here instead of httpclient
			 */
			new HttpClient(config)
				.get(`/stores/${props.match.params.storeId}/vendors`)
				.then((res: any) => {
					const allVendors = res.data.map((vendor: RowType) => {
						return {
							id: vendor.id,
							name: vendor.name,
							contact: vendor.contact,
							phone: vendor.phone,
							email: vendor.email,
						};
					});
					setVendors(allVendors);
				})
				.catch((error) => console.error(`Error: ${error}`));
		};
		getAllVendors();
	}, [config, props]);

	const [order, setOrder] = React.useState<"desc" | "asc">("asc");
	const [orderBy, setOrderBy] = React.useState("customer");
	const [selected, setSelected] = React.useState<Array<string>>([]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleRequestSort = (event: any, property: string) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		if (event.target.checked) {
			const newSelecteds: Array<string> = vendors.map(
				(n: RowType) => n.id
			);
			setSelected(newSelecteds);
			return;
		}
		setSelected([]);
	};

	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		id: string
	) => {
		const selectedIndex = selected.indexOf(id);
		let newSelected: Array<string> = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number
	) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const baseUrl: string = `/stores/${props.match.params.storeId}/vendors`;
	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	const emptyRows =
		rowsPerPage -
		Math.min(rowsPerPage, vendors.length - page * rowsPerPage);

	return (
		<div>
			<Paper>
				<EnhancedTableToolbar numSelected={selected.length} />
				<TableContainer>
					<Table
						aria-labelledby="tableTitle"
						size={"medium"}
						aria-label="enhanced table"
					>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={vendors.length}
						/>
						<TableBody>
							{stableSort(vendors, getComparator(order, orderBy))
								.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
								)
								.map((row: RowType, index: number) => {
									const isItemSelected = isSelected(row.id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											role="checkbox"
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={`${row.id}-${index}`}
											selected={isItemSelected}
										>
											<TableCell padding="checkbox">
												<Checkbox
													checked={isItemSelected}
													inputProps={{
														"aria-labelledby": labelId,
													}}
													onClick={(event) =>
														handleClick(
															event,
															row.id
														)
													}
												/>
											</TableCell>
											<TableCell align="left">
												{row.id}
											</TableCell>
											<TableCell align="left">
												{row.name}
											</TableCell>
											<TableCell align="left">
												{row.contact}
											</TableCell>
											<TableCell align="right">
												{row.phone}
											</TableCell>
											<TableCell align="left">
												{row.email}
											</TableCell>
											<TableCell
												padding="none"
												align="right"
											>
												<Box mr={2}>
													<IconButton aria-label="delete">
														<DeleteForever />
													</IconButton>
													<IconButton aria-label="edit" href={`${baseUrl}/${row.id}/edit`}>
														<Edit />
													</IconButton>
													<IconButton aria-label="details" href={`${baseUrl}/${row.id}/view`}>
														<RemoveRedEyeIcon />
													</IconButton>
												</Box>
											</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow style={{ height: 53 * emptyRows }}>
									<TableCell colSpan={8} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={vendors.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			</Paper>
		</div>
	);
}

function VendorsList(props: any) {
	return (
		<React.Fragment>
			<Helmet title="Vendors Admin" />
			<Grid justify="space-between" container spacing={10}>
				<Grid item>
					<Typography variant="h3" gutterBottom display="inline">
						Vendors
					</Typography>
				</Grid>
				<Grid item>
					<div>
						<Button variant="contained" color="primary" href={`/stores/${props.match.params.storeId}/vendors/create`}>
							<AddIcon />
							Add Vendor
						</Button>
					</div>
				</Grid>
			</Grid>
			<Grid container spacing={6}>
				<Grid item xs={12}>
					<EnhancedTable {...props}/>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

export default VendorsList;
