import React from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Form, Formik } from "formik";

import {
	Box,
	Button as MuiButton,
	Card as MuiCard,
	CardContent,
	CircularProgress,
	Grid,
	InputLabel,
	MenuItem,
} from "@material-ui/core";
import { spacing, SpacingProps } from "@material-ui/system";

import { StatusMessage } from "../../components/Form/Sections/StatusMessage";
import { SimpleTextField } from "../../components/Form/Fields/SimpleTextField";
import { alphanumericExtValidation } from "../../components/Form/validation/alphanumericExtValidation";
import { FormControl, Select } from "./styles";

const Card = styled(MuiCard)(spacing);

interface ButtonPropstype extends SpacingProps {
	component?: string;
}

const Button = styled(MuiButton)<ButtonPropstype>(spacing);
const prefixValidation = alphanumericExtValidation("prefix", false);
const suffixValidation = alphanumericExtValidation("suffix", false);

const validationSchema = Yup.object().shape({
	...prefixValidation,
	...suffixValidation,
	...{
		quantity: Yup.number().required(),
	},
	...{
		type: Yup.string().required(),
	},
	...{
		codeLength: Yup.number().required(),
	},
});

function VoucherCodeForm(props: any) {
	return (
		<Formik
			initialValues={props.initialValues}
			validationSchema={validationSchema}
			onSubmit={props.handleSubmit}
			enableReinitialize
		>
			{({
				errors,
				handleBlur,
				handleChange,
				handleSubmit,
				isSubmitting,
				touched,
				values,
				status,
			}) => (
				<Card mb={6}>
					<CardContent>
						<StatusMessage
							status={status}
							errors={errors}
							successMessage={"Successfully saved"}
						/>

						{isSubmitting ? (
							<Box display="flex" justifyContent="center" my={6}>
								<CircularProgress />
							</Box>
						) : (
							<Form onSubmit={handleSubmit}>
								<Grid container spacing={6}>
									<Grid item md={6}>
										<SimpleTextField
											errors={errors}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched}
											values={values}
											name="prefix"
											label="Prefix"
											placeholder="MY-PREFIX"
										/>
									</Grid>
									<Grid item md={6}>
										<SimpleTextField
											errors={errors}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched}
											values={values}
											name="suffix"
											label="Suffix"
											placeholder="-MY-SUFFIX"
										/>
									</Grid>
								</Grid>
								<Grid container spacing={6}>
									<Grid item md={6}>
										<SimpleTextField
											errors={errors}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched}
											values={values}
                                            required
											name="codeLength"
											label="Code Length"
											placeholder="40"
										/>
									</Grid>
									<Grid item md={6}>
										<SimpleTextField
											errors={errors}
											handleBlur={handleBlur}
											handleChange={handleChange}
											touched={touched}
											values={values}
                                            required
											name="quantity"
											label="Quantity"
											placeholder="25"
										/>
									</Grid>
								</Grid>
								<Grid container spacing={6}>
									<Grid item md={6}>
										<FormControl variant="outlined" m={0}>
											<InputLabel id="typeLabel">
												Type
											</InputLabel>
											<Select
												labelId="typeLabel"
												id="type"
												value={values.type || ""}
												onChange={handleChange("type")}
												label="Type"
                                                required
											>
												<MenuItem value="numeric">
													Numeric
												</MenuItem>
												<MenuItem
													value="string"
													selected
												>
													String
												</MenuItem>
											</Select>
										</FormControl>
									</Grid>
								</Grid>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									mt={3}
								>
									Save
								</Button>
							</Form>
						)}
					</CardContent>
				</Card>
			)}
		</Formik>
	);
}

export default VoucherCodeForm;
