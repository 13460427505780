import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			flexWrap: "wrap",
			minWidth: 300,
			width: "100%",
		},
		store: {
			position: "relative",
			height: 150,
			[theme.breakpoints.down("xs")]: {
				width: "100% !important",
				height: 100,
			},
			"&:hover, &$focusVisible": {
				zIndex: 1,
				"& $storeBackdrop": {
					opacity: 0.15,
				},
				"& $storeTitle": {
					border: "4px solid currentColor",
					borderRadius: "10px",
				},
			},
		},
		focusVisible: {},
		storeBackdrop: {
			position: "absolute",
			left: 0,
			right: 0,
			top: 0,
			bottom: 0,
			backgroundColor: "#989999",
			opacity: 0.4,
			transition: theme.transitions.create("opacity"),
			borderRadius: 15,
		},
		storeTitle: {
			position: "relative",
			padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
				theme.spacing(1) + 6
			}px`,
			width: "70%",
			textAlign: "center",
		},
		storeUnderline: {
			height: 3,
			width: 40,
			backgroundColor: theme.palette.common.white,
			position: "absolute",
			bottom: -4,
			left: "calc(50% - 20px)",
			transition: theme.transitions.create("opacity"),
		},
	})
);
