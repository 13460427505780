import { OfferApiDto, OfferFormDto } from "./module";
import moment from "moment";

export function toFormValues(apiData: OfferApiDto): OfferFormDto {
	return {
		name: apiData.name,
		productId: String(apiData.productId),
		value: String(apiData.value),
		dateFrom: moment(apiData.validFrom).format("YYYY-MM-DD"),
		dateTo: moment(apiData.validTo).format("YYYY-MM-DD"),
		highlight: apiData.highlight,
		redemptionAdvice: apiData.redemptionAdvice,
		tos: apiData.tos,
		codePoolId: apiData.codePoolId,
        image: apiData.image,
	};
}

export function toApiPayload(values: OfferFormDto): OfferApiDto {
	return {
		name: values.name,
		productId: Number(values.productId),
		value: Number(values.value),
		validFrom: new Date(values.dateFrom).toISOString(),
		validTo: new Date(String(values.dateTo)).toISOString(),
		highlight: values.highlight,
		redemptionAdvice: values.redemptionAdvice,
		tos: values.tos,
		codePoolId: values.codePoolId,
        image: values.image,
	};
}
