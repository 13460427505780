import { CodepoolApiDto, CodepoolFormDto } from "./module";
import moment from "moment";

export function toFormValues(apiData: CodepoolApiDto): CodepoolFormDto {
    return {
        name: apiData.name,
        dateFrom: moment(apiData.validFrom).format("YYYY-MM-DD"),
        dateTo:
            apiData.validTo != null
                ? moment(apiData.validTo).format("YYYY-MM-DD")
                : undefined,
    };
}

export function toApiPayload(values: CodepoolFormDto): CodepoolApiDto {
    return {
        name: values.name,
        validFrom: new Date(values.dateFrom).toISOString(),
        validTo:
            values.dateTo != null
                ? new Date(String(values.dateTo)).toISOString()
                : undefined,
    };
}
