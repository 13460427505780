import styled from "styled-components/macro";
import {
    AppBar as MuiAppBar,
    IconButton as MuiIconButton,
  } from "@material-ui/core";

export const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

export const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;
