import * as Yup from "yup";

export const alphanumericValidation = (
    name: string,
    required: boolean = true
): any => {
    const validation = Yup.string().matches(
        /^[a-zA-Z0-9 ]+$/,
        "Must be alphanumeric with spaces"
    );
    return required
        ? { [name]: validation.required("Required") }
        : { [name]: validation };
};
