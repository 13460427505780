import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import {
	Grid,
	Divider as MuiDivider,
	Typography as MuiTypography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { useStyles } from "./styles";
import { httpService } from "../../services/httpService";
import { useSelector } from "react-redux";
import { Store } from "./module";

const Divider = styled(MuiDivider)(spacing);

const MTypography = styled(MuiTypography)(spacing);

function Stores() {
	const classes = useStyles();
	const config = useSelector((state: any) => state.config);
	const [stores, setStores] = useState([]);
	useEffect(() => {
		httpService
			.getAll(config, `/stores/`)
			.then((res: any) => setStores(res));
	}, [config]);

	return (
		<React.Fragment>
			<Helmet title="Store Selection" />
			<Grid justify="space-between" container spacing={6}>
				<Grid item>
					<MTypography variant="h1" gutterBottom>
						Welcome back!
					</MTypography>
					<MTypography variant="subtitle1">
						Please select a store from the options bellow.{" "}
						<span role="img" aria-label="Down arrow">
							⤵️
						</span>
					</MTypography>
				</Grid>
			</Grid>
			<Divider my={6} />
			<div className={classes.root}>
				{stores.map((store: Store) => (
					<ButtonBase
						focusRipple
						key={store.id}
						className={classes.store}
						focusVisibleClassName={classes.focusVisible}
						href={`/stores/${store.id}`}
						style={{
							width: "30%",
							marginRight: "0.5rem",
						}}
					>
						<span className={classes.storeBackdrop} />
						<Typography
							component="span"
							variant="h3"
							color="inherit"
							className={classes.storeTitle}
						>
							{store.name}
							<span className={classes.storeUnderline} />
						</Typography>
					</ButtonBase>
				))}
			</div>
		</React.Fragment>
	);
}

export default Stores;
