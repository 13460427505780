import axios, { AxiosInstance } from "axios";
interface IConfig {
    BASE_URL: string;
}
export default class HttpClient {
    private axiosInstance: AxiosInstance;

    constructor(config: IConfig) {
        this.axiosInstance = axios.create({
            baseURL: config.BASE_URL,
            timeout: 1000,
        });
    }

    get = async (endpoint: string) => {
        return new Promise((res, rej) => {
            this.axiosInstance
                .get(endpoint)
                .then((response) => {
                    res(response.data);
                })
                .catch((error) => {
                    if (false === this.redirect(error.response.status)) {
                        rej(Error(error.response.data.message));
                    }
                });
        })
    };

    post = async (endpoint: string, payload: any) => {
        return this.axiosInstance
            .post(endpoint, payload)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                this.redirect(error.response.status);
                throw new Error(error.response.data.message);
            });
    };

    patch = async (endpoint: string, payload: any) => {
        return this.axiosInstance
            .patch(endpoint, payload)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                this.redirect(error.response.status);
                throw new Error(error.response.data.message);
            });
    };

    /**
     * @note Redirects depending on given status.
     */
    redirect = (status: number): Boolean => {
        if (status === 401) {
            window.location.replace("/api/saml2/login");
            return true;
        }
        if (window.location.pathname !== "/error/403" && status === 403) {
            window.location.replace("/error/403");
            return true;
        }
        if (window.location.pathname !== "/error/404" && status === 404) {
            window.location.replace("/error/404");
            return true;
        }
        return false;
    };
}
