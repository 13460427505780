import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { Helmet } from "react-helmet-async";

import {
	Grid,
	Divider as MuiDivider,
	Typography as MuiTypography,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { useSelector } from "react-redux";
import { httpService } from "../../services/httpService";
import { Store } from "../stores/module";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Default(props: any) {
	const config = useSelector((state: any) => state.config);
	const [store, setStore] = useState({} as Store);
	useEffect(() => {
		httpService
			.getById(config, `/stores/${props.match.params.storeId}`)
			.then((res: any) => setStore(res));
	}, [config, props]);

	return (
		<React.Fragment>
			<Helmet title="Default Dashboard" />
			<Grid justify="space-between" container spacing={6}>
				<Grid item>
					<Typography variant="h1">
						You are in {store.name}
					</Typography>
				</Grid>
			</Grid>
			<Divider my={6} />
			<Grid justify="space-between" container spacing={6}>
				<Grid item>
					<Typography variant="h5" mb={3}>
						Exciting content is coming to this section.
					</Typography>
					<Typography variant="subtitle1">
						In the meantime, you can send Reports using the sidebar.
					</Typography>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

export default Default;
