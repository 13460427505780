import styled from "styled-components/macro";
import {
	Button as MuiButton,
	Card as MuiCard,
	Divider as MuiDivider,
	FormControl as MuiFormControl,
	Select as MuiSelect,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { ButtonPropstype, SelectMuiType } from "./module";

export const Divider = styled(MuiDivider)(spacing);
export const Card = styled(MuiCard)(spacing);

export const FormControlSpacing = styled(MuiFormControl)(spacing);

export const FormControl = styled(FormControlSpacing)<{ m?: number }>`
	min-width: 148px;
`;

export const Select = styled(MuiSelect)<SelectMuiType>(spacing);

export const Button = styled(MuiButton)<ButtonPropstype>(spacing);
