import React from "react";
import { FieldArray, Field } from "formik";
import { Box } from "@material-ui/core";
import ChipInput from "material-ui-chip-input";

export const EmailsField = ({
    errors,
    touched,
    values,
}: {
    errors: any;
    touched: any;
    values: any;
}) => {
    return (
        <Box mb={4} mt={4}>
            <FieldArray
                name="emails"
                render={(arrayHelpers) => (
                    <Field
                        name="emails"
                        component={() => (
                            <ChipInput
                                placeholder="Type an email and hit the Enter key"
                                fullWidth
                                value={values.emails}
                                label="Emails*"
                                error={
                                    errors.emails && touched.emails
                                        ? true
                                        : false
                                }
                                onAdd={(chip) => arrayHelpers.push(chip)}
                                onDelete={(_chip, index) =>
                                    arrayHelpers.remove(index)
                                }
                                variant="outlined"
                                helperText={
                                    errors.emails && touched.emails
                                        ? errors.emails
                                        : ""
                                }
                            />
                        )}
                    />
                )}
            />
        </Box>
    );
};
