import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";

import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    CircularProgress,
    Divider as MuiDivider,
    Typography,
} from "@material-ui/core";
import { spacing, SpacingProps } from "@material-ui/system";

import { Helmet } from "react-helmet-async";
import { DateFields } from "../../components/Form/Fields/DateFields";
import { NameField } from "../../components/Form/Fields/NameField";
import { StatusMessage } from "../../components/Form/Sections/StatusMessage";
import { useSelector } from "react-redux";
import MyBreadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { openDateValidation } from "../../components/Form/validation/openDateValidation";
import { nameValidation } from "../../components/Form/validation/nameValidation";
import { httpService } from "../../services/httpService";
import { CodepoolFormDto } from "./module";
import { toApiPayload, toFormValues } from "./transformers";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

interface ButtonPropstype extends SpacingProps {
    component?: string;
}

const Button = styled(MuiButton)<ButtonPropstype>(spacing);

const initialValues: CodepoolFormDto = {
    name: "",
    dateFrom: "",
    dateTo: "",
};

const validationSchema = Yup.object().shape({
    ...nameValidation,
    ...openDateValidation,
});

function Form(props: any) {
    const [codepool, setCodepool] = useState(initialValues);
    const config = useSelector((state: any) => state.config);

    const handleSubmit = async (values: any, functions: any) => {
        httpService.update(
            toApiPayload(values),
            { ...functions, toFormValues },
            config,
            `/stores/${props.match.params.storeId}/vendors/${props.match.params.vendorId}/codePools/${props.match.params.codepoolId}`
        );
    };

    useEffect(() => {
        httpService
            .getById(
                config,
                `/stores/${props.match.params.storeId}/vendors/${props.match.params.vendorId}/codePools/${props.match.params.codepoolId}`
            )
            .then((res: any) => setCodepool(toFormValues(res)));
    }, [config, props]);

    return (
        <Formik
            initialValues={codepool}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
            }) => (
                <Card mb={6}>
                    <CardContent>
                        <Typography variant="body2" gutterBottom>
                            To edit this Codepool, please make the changes by
                            completing the following fields
                        </Typography>
                        <StatusMessage
                            status={status}
                            errors={errors}
                            successMessage={"Successfully saved"}
                        />

                        {isSubmitting ? (
                            <Box display="flex" justifyContent="center" my={6}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <NameField
                                    errors={errors}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    touched={touched}
                                    values={values}
                                />
                                <DateFields
                                    errors={errors}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    touched={touched}
                                    values={values}
                                    dateFromRequired
                                    dateToRequired={false}
                                />

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    mt={3}
                                >
                                    Save
                                </Button>
                            </form>
                        )}
                    </CardContent>
                </Card>
            )}
        </Formik>
    );
}

function CodepoolEdit(props: any) {
    const breadcrumbs = {
        links: [
            {
                name: "Vendors",
                to: `/stores/${props.match.params.storeId}/vendors`,
            },
        ],
        current: "Edit Codepool",
    };

    return (
        <React.Fragment>
            <Helmet title="Vendors - Edit Codepool" />
            <Typography variant="h3" gutterBottom display="inline">
                Edit Codepool
            </Typography>
            <MyBreadcrumbs {...breadcrumbs} />
            <Divider my={6} />
            <Form {...props} />
        </React.Fragment>
    );
}

export default CodepoolEdit;
