import React, { useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/AppBar/AppBar";
import Footer from "../../components/Footer/Footer";
import { Hidden, CssBaseline, withWidth } from "@material-ui/core";

import { isWidthUp } from "@material-ui/core/withWidth";
import {
	AppContent,
	DashboardPropsType,
	Drawer,
	drawerWidth,
	GlobalStyle,
	MainContent,
	Root,
} from "./styles";

const ReducedDashboard: React.FC<DashboardPropsType> = ({ children, routes, width }) => {
	const [mobileOpen, setMobileOpen] = useState(false);

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	return (
		<Root>
			<CssBaseline />
			<GlobalStyle />
			<Drawer>
				<Hidden smDown implementation="css">
					<Sidebar
						disableMenu={true}
						routes={routes}
						PaperProps={{ style: { width: drawerWidth } }}
					/>
				</Hidden>
			</Drawer>
			<AppContent>
				<Header onDrawerToggle={handleDrawerToggle} />
				<MainContent p={isWidthUp("lg", width) ? 12 : 5}>
					{children}
				</MainContent>
				<Footer />
			</AppContent>
		</Root>
	);
};

export default withWidth()(ReducedDashboard);
