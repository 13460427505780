import React, { useState } from "react";
import * as ImageWrapper from "./styles";
import { Upload } from "react-feather";
import { Button } from "@material-ui/core";

export default function ImageInput({ setFieldValue }: { setFieldValue: any }) {
	const [imagePreview, setImagePreview] = useState("");
	const [name, setName] = useState("");
	const [size, setSize] = useState("");

	const remove = () => {
		setImagePreview("");
		setName("");
		setSize("");
		setFieldValue("image", {});
	};

	const handleFileChange = (e: any) => {
		const file = e.currentTarget.files[0];
		if (file) {
			const reader = new FileReader();
			if (reader !== undefined && file !== undefined) {
				reader.onloadend = () => {
					setSize(file.size);
					setName(file.name);
					setImagePreview(reader.result as string);
					const base64 = (reader.result as string).split(";base64,");
					setFieldValue("image", {
						mime: file.type,
						body: base64[1],
					});
				};
				reader.readAsDataURL(file);
			}
		}
	};

	return (
		<ImageWrapper.Container>
			<ImageWrapper.Card
				width={imagePreview === "" ? 12 : 12}
				height={imagePreview === "" ? 13 : 14}
			>
				<ImageWrapper.Item
					top={imagePreview === "" ? 0 : -50}
					width={imagePreview === "" ? 120 : 145}
					height={imagePreview === "" ? 120 : 145}
				>
					{imagePreview === "" ? (
						<Upload />
					) : (
						<img src={imagePreview} alt="offer" />
					)}
					<input
						type="file"
						name="image"
						id="image"
						accept=".jpef, .png, .jpg"
						src={imagePreview}
						onChange={(event: any) => {
							handleFileChange(event);
						}}
					/>
				</ImageWrapper.Item>

				{imagePreview !== "" && (
					<React.Fragment>
						<section>
							<span>{name}</span>
							<span>Size: {size}</span>
						</section>

						<Button
							variant="contained"
							color="secondary"
							onClick={remove}
						>
							Remove
						</Button>
					</React.Fragment>
				)}
			</ImageWrapper.Card>
		</ImageWrapper.Container>
	);
}
