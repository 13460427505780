import * as React from "react";
import { Power } from "react-feather";

import { Tooltip, Menu, MenuItem } from "@material-ui/core";
import { IconButton } from "./styles";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import HttpClient from "../../utils/httpClient";

function UserDropdown() {
    const [anchorMenu, setAnchorMenu] = useState<any>(null);

    const toggleMenu = (event: React.SyntheticEvent) => {
        setAnchorMenu(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorMenu(null);
    };

    const handleSignOut = async () => {
        window.location.href = "/api/saml2/logout/";
    };

    const [user, setUser] = useState([]);
    const config = useSelector((state: any) => state.config);

    useEffect(() => {
        const getUser = () => {
            new HttpClient(config)
                .get(`/user`)
                .then((res: any) => {
                    setUser(res.datum.firstName);
                })
                .catch((error) => console.error(`Error: ${error}`));
        };
        getUser();
    }, [config]);

    return (
        <React.Fragment>
            <Tooltip title="Account">
                <IconButton
                    aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
                    aria-haspopup="true"
                    onClick={toggleMenu}
                    color="inherit"
                >
                    <Power />
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-appbar"
                anchorEl={anchorMenu}
                open={Boolean(anchorMenu)}
                onClose={closeMenu}
            >
                <UserName user={user} closeMenu={closeMenu} />
                <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
            </Menu>
        </React.Fragment>
    );
}

const UserName = ({ user, closeMenu }: { user: any; closeMenu: any }) => {
    if (user && user.length > 0) {
        return <MenuItem selected>Hello, {user}</MenuItem>;
    }
    return <MenuItem onClick={closeMenu}>Profile</MenuItem>;
};

export default UserDropdown;
