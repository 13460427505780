import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

fetch("/config.json")
    .then((data) => data.json())
    .then((config) => {
        ReactDOM.render(
            <Provider
                store={createStore(() => {
                    return { currentTheme: "DARK", config };
                }, composeWithDevTools(applyMiddleware(thunk)))}
            >
                <App />
            </Provider>,
            document.getElementById("root")
        );
    })
    .catch((e) => {
        console.error("Impossible to retrieve configuration");
    });
