import { spacing } from "@material-ui/system";
import styled from "styled-components/macro";
import { Divider as MuiDivider, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet-async";
const Divider = styled(MuiDivider)(spacing);

export const Title = (props: any) => {
    return (
        <>
            <Helmet title="Reports" />
            <Typography variant="h1" gutterBottom display="inline">
                {props.name} Report
            </Typography>
            <Divider my={6} />
        </>
    );
};
