
export type RowType = {
	[key: string]: string | number;
	id: string;
	name: string;
	contact: string;
	phone: string;
	email: string;
};

function descendingComparator(a: RowType, b: RowType, orderBy: string) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

export function getComparator(order: "desc" | "asc", orderBy: string) {
	return order === "desc"
		? (a: RowType, b: RowType) => descendingComparator(a, b, orderBy)
		: (a: RowType, b: RowType) => -descendingComparator(a, b, orderBy);
}

export function stableSort(
	array: Array<RowType>,
	comparator: (a: RowType, b: RowType) => number
) {
	const stabilizedThis = array.map((el: RowType, index: number) => ({
		el,
		index,
	}));
	stabilizedThis.sort((a, b) => {
		const order = comparator(a.el, b.el);
		if (order !== 0) return order;
		return a.index - b.index;
	});
	return stabilizedThis.map((element) => element.el);
}
