import React from "react";
import styled from "styled-components/macro";

import {
    Grid,
    TextField as MuiTextField,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

export const SkuField = ({
    errors,
    handleBlur,
    handleChange,
    touched,
    values,
}: {
    errors: any;
    handleBlur: any;
    handleChange: any;
    touched: any;
    values: any;
}) => {
    return (
        <Grid container spacing={6}>
            <Grid item md={12}>
                <TextField
                    id="sku"
                    label="Sku"
                    type="string"
                    value={values.sku}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={Boolean(touched.sku && errors.sku)}
                    fullWidth
                    helperText={touched.sku && errors.sku}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                    placeholder="YOUR-SKU-EXAMPLE-000000000"
                    required
                />
            </Grid>
        </Grid>
    );
};
