import * as Yup from "yup";

export const alphanumericExtValidation = (
    name: string,
    required: boolean = true
): any => {
    const validation = Yup.string().matches(
        /^[a-zA-Z0-9\-_, ]+$/,
        "Must be alphanumeric with hyphen/underscore/spaces"
    );
    return required
        ? { [name]: validation.required("Required") }
        : { [name]: validation };
};
