import * as React from "react";
import { withTheme } from "styled-components/macro";
import { Grid, Hidden, Toolbar } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { AppBar, IconButton } from "./styles";
import UserDropdown from "../UserDropdown/UserDropdown";

type AppBarProps = {
  theme: {};
  onDrawerToggle: React.MouseEventHandler<HTMLElement>;
};

const AppBarComponent: React.FC<AppBarProps> = ({ onDrawerToggle }) => (
  <React.Fragment>
    <AppBar position="sticky" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
          <Grid item xs />
          <Grid item>
            <UserDropdown />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
);

export default withTheme(AppBarComponent);
