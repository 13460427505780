import * as Yup from "yup";

export const nameValidation = {
    name: Yup.string()
        .matches(
            /^[a-zA-Z0-9\-_ ]+$/,
            "Must be alphanumeric with hyphen/underscore/spaces"
        )
        .required("Required"),
};
