import React from "react";
import { CssBaseline } from "@material-ui/core";
import { Root, GlobalStyle } from "./styles";

const Auth: React.FC = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  );
};

export default Auth;
