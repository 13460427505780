import { VoucherCodeApiDto, VoucherCodeFormDto } from "./module";

export function toApiPayload(values: VoucherCodeFormDto): VoucherCodeApiDto {
	return {
		prefix: values.prefix,
		suffix: values.suffix,
		codeLength: Number(values.codeLength),
		quantity: Number(values.quantity),
		type: values.type,
	};
}
