import React from "react";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";

import {
    Box,
    Button as MuiButton,
    Card as MuiCard,
    CardContent,
    CircularProgress,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";

import { ButtonPropstype } from "../module";
import { EmailsField } from "../../../components/Form/Fields/EmailsField";
import { SkuField } from "../../../components/Form/Fields/SkuField";
import { CardHeader } from "../Form/CardHeader";
import { skuValidation } from "../../../components/Form/validation/skuValidation";
import { emailValidation } from "../../../components/Form/validation/emailValidation";
import { StatusMessage } from "../../../components/Form/Sections/StatusMessage";
import { httpService } from "../../../services/httpService";

const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)<ButtonPropstype>(spacing);

const initialValues = {
    sku: "",
    emails: [],
};

const validationSchema = Yup.object().shape({
    ...skuValidation,
    ...emailValidation,
});

const CustomersForm = () => {
    const config = useSelector((state: any) => state.config);

    const handleSubmit = async (values: any, functions: any) =>
        httpService.create(values, functions, config, "/reports/customers");

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                status,
            }) => (
                <Card mb={6}>
                    <CardContent>
                        <CardHeader />
                        <StatusMessage
                            status={status}
                            errors={errors}
                            successMessage={
                                "Your report has been queued and should be with you within the next hour."
                            }
                        />

                        {isSubmitting ? (
                            <Box display="flex" justifyContent="center" my={6}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <SkuField
                                    errors={errors}
                                    handleBlur={handleBlur}
                                    handleChange={handleChange}
                                    touched={touched}
                                    values={values}
                                />
                                <EmailsField
                                    errors={errors}
                                    touched={touched}
                                    values={values}
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    mt={3}
                                >
                                    Send
                                </Button>
                            </form>
                        )}
                    </CardContent>
                </Card>
            )}
        </Formik>
    );
};

export default CustomersForm;
