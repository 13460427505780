import React from "react";
import styled from "styled-components/macro";

import {
    Divider as MuiDivider,
    Typography,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import MyBreadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { httpService } from "../../services/httpService";
import VoucherCodeForm from "./VoucherCodeForm";
import { toApiPayload } from './transformers';

const Divider = styled(MuiDivider)(spacing);

function VoucherCodeAdd(props: any) {
    const breadcrumbs = {
        links: [
            {
                name: "Vendors",
                to: `/stores/${props.match.params.storeId}/vendors`,
            },
            { /** @todo Use view codepool in here instead of edit (Not built yet) */
                name: "Codepools",
                to: `/stores/${props.match.params.storeId}/vendors/${props.match.params.vendorId}/codepools/${props.match.params.codepoolId}`,
            },
        ],
        current: "Generate Voucher Codes",
    };
    const config = useSelector((state: any) => state.config);
    const handleSubmit = async (values: any, functions: any) => {
        httpService.create(
            toApiPayload(values),
            functions,
            config,
            `/stores/${props.match.params.storeId}/vendors/${props.match.params.vendorId}/codepools/${props.match.params.codepoolId}/codes/generate`
        );
    };

    return (
        <React.Fragment>
            <Helmet title="Vendors - Generate Voucher Codes" />
            <Typography variant="h3" gutterBottom display="inline">
                Generate Voucher Codes
            </Typography>
            <MyBreadcrumbs {...breadcrumbs} />
            <Divider my={6} />
            <VoucherCodeForm {...props} handleSubmit={handleSubmit} initialValues={{}} />
        </React.Fragment>
    );
}

export default VoucherCodeAdd;
