import React from "react";
import SalesForm from "./Sales/Sales";
import RefundsForm from './Refunds/Refunds';
import StockForm from "./Stock/Stock";
import ProductsForm from "./Products/Products";
import CustomersForm from "./Customers/Customers";
import { Title } from "./Title";
import { reportComponents } from "../../types/reportComponents";

/**
 * @note We choose the component type at runtime in here
 */
const components: reportComponents = {
    Sales: SalesForm,
    Refunds: RefundsForm,
    Stock: StockForm,
    Products: ProductsForm,
    Customers: CustomersForm,
}

function Reports({name}: any) {
    const Form = components[name];
    return (
        <React.Fragment>
            <Title name={name} />
            <Form />
        </React.Fragment>
    );
}

export default Reports;
