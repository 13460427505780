import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";

import {
	Box,
	CardContent,
	CircularProgress,
	Grid,
	Typography,
	InputLabel,
	MenuItem,
} from "@material-ui/core";

import { Helmet } from "react-helmet-async";
import { StatusMessage } from "../../components/Form/Sections/StatusMessage";
import { useSelector } from "react-redux";
import MyBreadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import { nameValidation } from "../../components/Form/validation/nameValidation";
import { httpService } from "../../services/httpService";
import { SimpleTextField } from "../../components/Form/Fields/SimpleTextField";
import { makeStyles } from "@material-ui/styles";
import { DateFields } from "../../components/Form/Fields/DateFields";
import { alphanumericTextValidation } from "../../components/Form/validation/alphanumericTextValidation";
import { datesValidation } from "../../components/Form/validation/dateValidation";
import { toApiPayload, toFormValues } from "./transformers";
import ImageInput from "./image/ImageInput";
import { Button, Card, Divider, FormControl, Select } from "./styles";

const initialValues = {
	name: "",
	productId: "",
	value: "",
	dateFrom: "",
	dateTo: "",
	highlight: "",
	redemptionAdvice: "",
	tos: "",
	codePoolId: "",
};
const highlightValidation = alphanumericTextValidation("highlight");
const redemptionAdviceValidation = alphanumericTextValidation(
	"redemptionAdvice"
);
const tosValidation = alphanumericTextValidation("tos");

const validationSchema = Yup.object().shape({
	...nameValidation,
	...{ productId: Yup.number() },
	...{ value: Yup.number() },
	...datesValidation,
	...highlightValidation,
	...redemptionAdviceValidation,
	...tosValidation,
});
const useStyles = makeStyles(() => ({
	detailsSection: {
		marginTop: "1.3rem",
		marginBottom: "0.5rem",
	},
}));

function Form(props: any) {
	const classes = useStyles();
	const config = useSelector((state: any) => state.config);
	const handleSubmit = async (values: any, functions: any) => {
		if (values.image?.body === undefined) {
			delete values.image;
		}
		httpService.update(
			toApiPayload(values),
			{ ...functions, toFormValues },
			config,
			`/stores/${props.match.params.storeId}/vendors/${props.match.params.vendorId}/offers/${props.match.params.productId}`
		);
	};

	const [offer, setOffer] = useState(initialValues);
	const [codepools, setCodepools] = useState([]);

	/**
	 * @todo Assign the right responses to these .then(TYPE)s
	 */
	useEffect(() => {
		httpService
			.getById(
				config,
				`/stores/${props.match.params.storeId}/vendors/${props.match.params.vendorId}/offers/${props.match.params.productId}`
			)
			.then((res: any) => {
				setOffer(toFormValues(res));
			});
		httpService
			.getAll(
				config,
				`/stores/${props.match.params.storeId}/vendors/${props.match.params.vendorId}/codepools`
			)
			.then((res: any) => {
				setCodepools(res);
			});
	}, [config, props]);

	return (
		<Formik
			initialValues={offer}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
			enableReinitialize
		>
			{(props: any) => (
				<Card mb={6}>
					<CardContent>
						<StatusMessage
							status={props.status}
							errors={props.errors}
							successMessage={"Successfully saved"}
						/>

						{props.isSubmitting ? (
							<Box display="flex" justifyContent="center" my={6}>
								<CircularProgress />
							</Box>
						) : (
							<form onSubmit={props.handleSubmit}>
								<Grid container spacing={6}>
									<Grid item md={4}>
										<SimpleTextField
											{...props}
											name="name"
											label="Name"
											placeholder="My Offer name"
										/>
									</Grid>
									<Grid item md={4}>
										<SimpleTextField
											{...props}
											name="productId"
											label="Product ID"
											placeholder=""
										/>
									</Grid>
									<Grid item md={4}>
										<SimpleTextField
											{...props}
											name="value"
											label="Value"
											placeholder=""
										/>
									</Grid>
								</Grid>
								<DateFields {...props} />
								<Grid
									item
									xs
									zeroMinWidth
									className={classes.detailsSection}
								>
									<Typography variant="body2" gutterBottom>
										Specific conditions and details
									</Typography>
								</Grid>
								<Grid container spacing={6}>
									<Grid item md={6}>
										<SimpleTextField
											{...props}
											name="highlight"
											label="Highlight"
											placeholder="Highlight goes in here"
											multiline={true}
										/>
									</Grid>
									<Grid item md={6}>
										<SimpleTextField
											{...props}
											name="redemptionAdvice"
											label="Redemption Advice"
											placeholder="Redemption advice goes in here"
											multiline={true}
										/>
									</Grid>
									<Grid item md={12}>
										<SimpleTextField
											{...props}
											name="tos"
											label="Terms and Conditions"
											placeholder="Terms and conditions go in here"
											multiline={true}
										/>
									</Grid>
								</Grid>
								<Grid
									item
									xs
									zeroMinWidth
									className={classes.detailsSection}
								>
									<Typography variant="body2" gutterBottom>
										Codepool details
									</Typography>
								</Grid>
								<Grid container spacing={6}>
									<Grid item md={6}>
										<FormControl variant="outlined" m={0}>
											<InputLabel id="codePoolIdLabel">
												Codepool
											</InputLabel>
											<Select
												labelId="codePoolIdLabel"
												id="codePoolId"
												value={props.values.codePoolId || ""}
												onChange={props.handleChange(
													"codePoolId"
												)}
												label="Codepool"
											>
												{codepools.length > 0 &&
													codepools.map(
														(codepool: any) => (
															<MenuItem
																value={
																	codepool.id
																}
																key={
																	codepool.id
																}
															>
																{codepool.name}
															</MenuItem>
														)
													)}
											</Select>
										</FormControl>
									</Grid>
								</Grid>
								<Grid
									item
									xs
									zeroMinWidth
									className={classes.detailsSection}
								>
									<Typography variant="body2" gutterBottom>
										Offer image
									</Typography>
								</Grid>
								<Grid container spacing={6}>
									<Grid item md={4}>
										<ImageInput
											setFieldValue={props.setFieldValue}
										/>
									</Grid>
								</Grid>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									mt={3}
								>
									Save
								</Button>
							</form>
						)}
					</CardContent>
				</Card>
			)}
		</Formik>
	);
}

function OfferEdit(props: any) {
	const breadcrumbs = {
		links: [
			{
				name: "Vendors",
				to: `/stores/${props.match.params.storeId}/vendors`,
			},
		],
		current: "Edit Offer",
	};

	return (
		<React.Fragment>
			<Helmet title="Vendors - Edit Offer" />
			<Typography variant="h3" gutterBottom display="inline">
				Edit Offer
			</Typography>
			<MyBreadcrumbs {...breadcrumbs} />
			<Divider my={6} />
			<Form {...props} />
		</React.Fragment>
	);
}

export default OfferEdit;
