import styled from "styled-components/macro";

import { IconButton as MuiIconButton } from "@material-ui/core";

export const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;
